import { AppType } from "@/shared/app-common";
import axios from "@/shared/axios";
import {
  AIInsightDetail,
  FeedbackDetailModel,
  HomeArticleModel,
  HomeGPTsModel,
  HomeThemeModel,
  InsAccountModel,
  InsMonitorInfoModel,
  InsMonitorModel,
  InsMonitorTimeModel,
  OrderInfoModel,
  OrderModel,
  PaymentHistoryModel,
  SingleOrderTimesModel,
  UnAddAccountModel,
  UserModel,
} from "@/types/home";
import { BaseResponse } from "@/types/web.interface";
import { ISitemapField } from "next-sitemap";

// 支付失败原因

export async function postPayFaileApi(params: any) {
  const res = await axios.post<BaseResponse<any>>(
    `/stripe/fail_reason`,
    { ...params },
    {
      // headers: {
      //   'Current-Language': locale,
      // },
    }
  );
  return res.data;
}

// 获取历史购买
export async function getOverTimeData(actId: number) {
  const res = await axios.get<BaseResponse<any>>(
    `/ins/monitor/item/single/info?identification=${actId}`,
    {
      // headers: {
      //   'Current-Language': locale,
      // },
    }
  );
  return res.data;
}

//单次购买
export async function postSingleBuyApi(params: any) {
  const res = await axios.post<BaseResponse<any>>(
    `/ins/monitor/item/single/buy`,
    { ...params },
    {
      // headers: {
      //   'Current-Language': locale,
      // },
    }
  );
  return res.data;
}

export async function getThemeData(locale: string) {
  const res = await axios.get<BaseResponse<HomeThemeModel>>("/theme/get", {
    headers: {
      "Current-Language": locale,
      tenantId: `${process.env.NEXT_APP_TENANTID}`,
    },
    baseURL: process.env.NEXT_APP_BLOG_API_HOST,
  });
  return res.data;
}

export async function getGptsListHotData(locale: string) {
  const res = await axios.get<BaseResponse<[HomeGPTsModel]>>("/gpts/list/hot", {
    headers: {
      "Current-Language": locale,
    },
  });
  return res.data;
}

export async function getGptsListData(locale: string) {
  const res = await axios.get<BaseResponse<[HomeGPTsModel]>>("/gpts/list", {
    headers: {
      "Current-Language": locale,
    },
  });
  return res.data;
}

export async function getArticleListHotData(locale: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>(
    "/article/list/hot",
    {
      headers: {
        "Current-Language": locale,
      },
    }
  );
  return res.data;
}

export async function getArticleListData(params?: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>(
    "/article/list",
    {
      params,
      headers: {
        "Current-Language": locale,
        tenantId: `${process.env.NEXT_APP_TENANTID}`,
      },
      baseURL: process.env.NEXT_APP_BLOG_API_HOST,
    }
  );
  return res.data;
}

// article search list
export async function getArticleListSearchData(
  params: any,
  tagRequest: Boolean = false,
  locale?: string
) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>(
    tagRequest ? "/article/list/label" : "/article/list/search",
    {
      params,
      headers: {
        "Current-Language": locale,
      },
      baseURL: process.env.NEXT_APP_BLOG_API_HOST,
    }
  );
  return res.data;
}

// gpts search list
export async function getGptsListSearchData(params: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeGPTsModel]>>(
    "/gpts/list/search",
    {
      params,
      headers: {
        "Current-Language": locale,
      },
    }
  );
  return res.data;
}

// article detail
export async function getArticleDetailData(id: string, locale?: string) {
  const res = await axios.get<BaseResponse<HomeArticleModel>>(
    `/article/detail/title/${id}`,
    {
      headers: {
        "Current-Language": locale,
        tenantId: `${process.env.NEXT_APP_TENANTID}`,
      },
      baseURL: process.env.NEXT_APP_BLOG_API_HOST,
    }
  );
  return res.data;
}

// article detail
export async function getArticleDetailData1(id: string, locale?: string) {
  const res = await axios.get<BaseResponse<HomeArticleModel>>(
    `/article/detail/${id}`,
    {
      headers: {
        "Current-Language": locale,
        tenantId: `${process.env.NEXT_APP_TENANTID}`,
      },
      baseURL: process.env.NEXT_APP_BLOG_API_HOST,
    }
  );
  return res.data;
}

// article detail preview
export async function getArticlePreviewDetailData(id: string, locale?: string) {
  const res = await axios.get<BaseResponse<HomeArticleModel>>(
    `/article/preview/detail/${id}`,
    {
      headers: {
        "Current-Language": locale,
      },
    }
  );
  return res.data;
}

// gpts detail
export async function getGptsDetailData(id: string, locale?: string) {
  const res = await axios.get<BaseResponse<HomeGPTsModel>>(
    `/gpts/detail/${id}`,
    {
      headers: {
        "Current-Language": locale,
      },
    }
  );
  return res.data;
}

// article recommend
export async function getArticleRecommendList(params?: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>(
    `/article/list/recommend`,
    {
      params,
      headers: {
        "Current-Language": locale,
        tenantId: `${process.env.NEXT_APP_TENANTID}`,
      },
      baseURL: process.env.NEXT_APP_BLOG_API_HOST,
    }
  );
  return res.data;
}

// article recommend
export async function getArticleRecommendTags(params: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>(
    `/article/list/recommend/tag`,
    {
      params,
      headers: {
        "Current-Language": locale,
        tenantId: `${process.env.NEXT_APP_TENANTID}`,
      },
      baseURL: process.env.NEXT_APP_BLOG_API_HOST,
    }
  );
  return res.data;
}

// gpts recommend
export async function getGPTsRecommendList(params?: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeGPTsModel]>>(
    `/gpts/list/recommend`,
    {
      params,
      headers: {
        "Current-Language": locale,
      },
    }
  );
  return res.data;
}

// 明星个人信息

export async function getStartDataDetailApi(mediaName?: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>(
    `/ins/famous/detail/${mediaName}`,
    {
      headers: {
        "Current-Language": locale,
      },
    }
  );
  return res.data;
}

// 明星列表
export async function getStartDataListApi(params?: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>(
    `/ins/famous/page`,
    {
      params,
      headers: {
        "Current-Language": locale,
      },
    }
  );
  return res.data;
}

// category top list
export async function getCategoryTopList(params?: any, locale?: string) {
  const res = await axios.get<BaseResponse<[HomeArticleModel]>>(
    `/article/list/top`,
    {
      params,
      headers: {
        "Current-Language": locale,
        tenantId: `${process.env.NEXT_APP_TENANTID}`,
      },
      baseURL: process.env.NEXT_APP_BLOG_API_HOST,
    }
  );
  return res.data;
}

// category top list
export async function getSitemapList(params?: any, locale?: string) {
  const res = await axios.get<BaseResponse<[ISitemapField]>>(`/sitemap/list`, {
    baseURL: process.env.NEXT_APP_BLOG_API_HOST,
    params,
    headers: {
      "Current-Language": locale,
    },
  });
  return res.data;
}

// ==============================================================

// user update
export async function userUpdate(token: string, locale: string) {
  const res = await axios.post<BaseResponse<UserModel>>(
    `/user/update`,
    {
      "firebase-token": token,
      source_domain: document.referrer,
    },
    {
      headers: {
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

// /user/login_redirect
//
export async function loginRedirect(
  token: string,
  redirect: string,
  locale?: string
) {
  const res = await axios.post<BaseResponse<UserModel>>(
    `/user/login_redirect`,
    {
      "firebase-token": token,
      redirect,
    },
    {
      headers: {
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

// ins monitor list
export async function fetchInsMonitorList(locale?: string) {
  const res = await axios.get<BaseResponse<[InsMonitorModel]>>(
    `/ins/monitor/list`,
    {
      headers: {
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

// ins monitor search {name}
export async function fetchInsMonitorSearch(name: string, locale?: string) {
  const res = await axios.get<BaseResponse<[InsAccountModel]>>(
    `/ins/monitor/search/${name}`,
    {
      headers: {
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

// ins monitor add
export async function addInsMonitor(
  media_name: string,
  avatar: string,
  media_url: string,
  subs_id?: number,
  locale?: string
) {
  const res = await axios.post<BaseResponse<any>>(
    `/ins/monitor/add`,
    {
      media_name: media_name,
      avatar: avatar,
      media_url: media_url,
      subs_id,
    },
    {
      headers: {
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

// ins monitor remove {monitorId}
export async function removeInsMonitor(
  monitorId: string | number,
  locale?: string
) {
  const res = await axios.post<BaseResponse<any>>(
    `/ins/monitor/remove/${monitorId}`,
    {
      headers: {
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

// /ins/monitor/data/times/{identification}
export async function fetchInsMonitorDataTimes(
  identification: string,
  locale?: string
) {
  const res = await axios.get<BaseResponse<[InsMonitorTimeModel]>>(
    `/ins/monitor/data/times/${identification}`,
    {
      headers: {
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

// 获取可操作时间
export async function getActiveRecttiveTimeApi(mediaName?: any) {
  const res = await axios.get(`/ins/famous/date/${mediaName}`);
  return res.data;
}

// 通过时间获取详情
export async function getTenseFromDateNameApi(
  mediaName?: any,
  begin_date?: any,
  end_date?: any
) {
  const res = await axios.get(
    `/ins/famous/detail/${mediaName}?begin_date=${begin_date}&end_date=${end_date}`
  );
  return res.data;
}

// 获取活动页展示内容
export async function getActivePageContentApi() {
  const res = await axios.get<BaseResponse<InsMonitorInfoModel>>(
    `/ins/monitor/data/info/demo`
  );
  return res.data;
}

// /ins/monitor/data/info
export async function fetchInsMonitorDataInfo(
  identification: string,
  endTime?: string,
  dataId?: string,
  locale?: string
) {
  const res = await axios.get<BaseResponse<InsMonitorInfoModel>>(
    `/ins/monitor/data/info`,
    {
      params: {
        identification,
        endTime,
        dataId,
      },
      headers: {
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

// ins monitor list demo
export async function fetchInsMonitorListDemo(locale: any) {
  const res = await axios.get<BaseResponse<[InsMonitorModel]>>(
    `/ins/monitor/list/demo`,
    {
      headers: {
        "Current-Language": locale,
        "Accept-Language": locale,
      },
      baseURL: process.env.NEXT_APP_BLOG_API_HOST,
    }
  );
  return res.data;
}

// order subs
export async function singleBuyRequest(
  locale: any,
  weeks?: number,
  identification?: any
) {
  const res = await axios.post<BaseResponse<OrderModel>>(
    `/ins/monitor/item/single/buy`,
    {
      weeks,
      identification,
    },
    {
      headers: {
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

// order subs
export async function orderSubsRequest(params: any, locale?: any) {
  const res = await axios.post<BaseResponse<OrderModel>>(
    `/cobra/product/buy`,
    {
      ...params,
    },
    {
      headers: {
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

// order cancel
export async function orderSubsCancelRequest(
  reasonType: any,
  subs_id: any,
  locale: any
) {
  const res = await axios.post<BaseResponse<any>>(
    `/ins/monitor/item/subs/cancel`,
    {
      reasonType: reasonType,
      subs_id,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

// order resume
export async function orderResumeRequest(subs_id: any, locale: any) {
  const res = await axios.post<BaseResponse<any>>(
    `/ins/monitor/item/subs/resume`,
    {
      subs_id,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

// order upgrade
export async function orderUpgradeRequest(
  subsId: any,
  itemId: any,
  monitor_id: any,
  locale: any
) {
  const res = await axios.post<BaseResponse<any>>(
    `/ins/monitor/item/subs/upgrade`,
    {
      subs_id: subsId,
      item_id: itemId,
      monitor_id,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

// order subs query list
export async function fetchOrderSubsListRequest(locale: any) {
  const res = await axios.get<BaseResponse<[OrderInfoModel]>>(
    `/ins/monitor/item/list/subs/info`,
    {
      headers: {
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

// order subs query
export async function orderSubsQueryRequest(locale: any) {
  const res = await axios.get<BaseResponse<[OrderInfoModel]>>(
    `/cobra/product/list`,
    {
      headers: {
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

// single info
export async function fetchSingleTimes(identification: any, locale: any) {
  const res = await axios.get<BaseResponse<SingleOrderTimesModel>>(
    `/ins/monitor/item/single/info`,
    {
      params: {
        identification,
      },
      headers: {
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

// order search list
// 业务编码：ins监测：ins_monitor，配对：destined

export async function getOrderListSearchData(params: any, locale: any) {
  const res = await axios.get<BaseResponse<[PaymentHistoryModel]>>(
    "/stripe/order/list/ins_monitor",
    {
      params,
      headers: {
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

// /stripe/order/info/{bizCode}}
// 业务编码：ins_monitor-ins监测，destined-配对

export async function fetchPaidOrderInfoData(order_id: any, locale: any) {
  const res = await axios.get<BaseResponse<PaymentHistoryModel>>(
    `/stripe/order/info/ins_monitor`,
    {
      params: {
        order_id,
      },
      headers: {
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

// 未添加账号
export async function fetchMonitorUnaddData(locale: any) {
  const res = await axios.get<BaseResponse<[UnAddAccountModel]>>(
    `/ins/monitor/list/un_add`,
    {
      headers: {
        "Current-Language": locale,
        "Accept-Language": locale,
      },
    }
  );
  return res.data;
}

export async function fetchFeedBackData(locale?: string) {
  const res = await axios.get<BaseResponse<FeedbackDetailModel>>(
    `/survey/get`,
    {
      headers: {
        "Current-Language": locale,
      },
    }
  );
  return res.data;
}

export async function updateFeedbackData(params?: any) {
  const res = await axios.post<BaseResponse<any>>(`/survey/answer`, {
    ...params,
  });
  return res.data;
}

// AI 洞察
export async function fetchAIInsightsData(
  identification?: any,
  data_id?: any,
  locale?: string
) {
  const res = await axios.get<BaseResponse<AIInsightDetail>>(
    `/ins/monitor/data/insights`,
    {
      params: {
        identification,
        data_id,
      },
      headers: {
        "Current-Language": locale,
      },
    }
  );
  return res.data;
}

export async function updateMonitorFeedbackData(
  dataId?: any,
  point?: any,
  content?: any
) {
  const res = await axios.post<BaseResponse<any>>(
    `/ins/monitor/data/feedback`,
    {
      biz_id: dataId,
      point: point,
      content: content,
    }
  );
  return res.data;
}
